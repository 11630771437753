















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput, BFormRow,
  BFormSpinbutton,
  BSpinner
} from 'bootstrap-vue'
import { debounceLoadPublicSettings, PublicSettings, settingsModule } from '@/store/settings'
import { mergeDeep } from 'apollo-utilities'
import { cloneDeep, isNumber } from 'lodash-es'
import { parse } from 'envfile'
import category from '@/store/category'
import leftovers from '@/store/leftovers'
import terminalModule from '@/store/terminal'

@Component({ components: { BForm, BFormGroup, BFormInput, BFormCheckbox, BFormSpinbutton, BAlert, BButton, BSpinner, BFormFile, BFormRow } })
export default class Settings extends Vue {
  settings: PublicSettings = cloneDeep(settingsModule.state.publicSettings)

  get settingsState() { return settingsModule.state.publicSettings }

  @Watch('settingsState', { deep: true, immediate: true })
  settingsStateHandler(settingsState:
                         typeof settingsModule.state.publicSettings) {
    this.settings = mergeDeep(this.settings, settingsState)
  }

  @Watch('settings', { deep: true })
  settingsHandler(settings: PublicSettings) {
    settingsModule.mutations.setPublicSettings(settings)
  }

  formatter(options:{prefix?:string, suffix?:string, factor?: number}) {
    return (v:string|number) => {
      const value = (typeof v === 'number' && isNumber(options.factor))
        ? v * options.factor : v
      return `${options.prefix ?? ''}${value}${options.suffix ?? ''}`
    }
  }

  reset() {
    settingsModule.mutations.reset()
  }

  changeForKeyboard(settingType: keyof PublicSettings) {
    const settings = this.settings
    return (v:KeyboardEvent) => {
      if (typeof settings[settingType] === 'number') {
        if (v.key.toLowerCase() === 'backspace') {
          (settings[settingType] as number) =
            parseInt(`${settings[settingType]}`.slice(0, -1))
        } else {
          const number = parseInt(v.key)
          if (!isNaN(number)) {
            (settings[settingType] as number) =
              parseInt(`${settings[settingType]}${number}`)
          }
        }
      }
    }
  }

  get isEnableSentry() {
    return settingsModule.getters.isEnableSentry
  }

  loadingPublicSettings = 0
  async loadPublicSettings() {
    this.loadingPublicSettings += 1
    try {
      await debounceLoadPublicSettings()
    } finally {
      this.loadingPublicSettings -= 1
    }
  }

  settingFile: File | null = null

  @Watch('settingFile')
  async handlerSettingFile(settingFile:File|null) {
    if (settingFile) {
      const settings = parse(await settingFile.text())
      settingsModule.mutations.setPublicSettings(settings)
    }
  }

  async reloadModules() {
    await Promise.all([
      category.actions.startWatchingSubscriptionsAndQueries(),
      leftovers.load(),
      terminalModule.actions.startWatchingSubscriptionsAndQueries()
    ])
  }

  get settingPlaceholder() {
    return `Текущая версия "${this.settings.ENV_VERSION}"`
  }

  reload() {
    window.location.reload()
  }
}
